.nav {
  /* flex box layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px;
  background-color: #ffffff;
  overflow: hidden;
  border-bottom-width: 2px;
}

.nav a {
  text-decoration: none;
}

.nav-title {
  max-height: 125px;
  color: #313030;
  padding-left: 10px;
  margin: 0;
}

.navLink {
  display: inline-block;
  text-align: center;
  padding: 24px 10px;
  text-decoration: none;
  font-size: 15px;
  transform: scale(2);
  /* these last two make the logout button in
  the nav bar look like another link */
  border: none;
  cursor: pointer;
  outline: 0;
}

.navLink:hover {
  background-color: #F3BFCE;
}

.medical {
  color: #F44C1D;
  stroke: #c30b0b;
  
}

.business {
  color: #F3D73C;
  stroke: #716153;
}

.political {
  color: #1D68DE;
  stroke: #0e17bd;
}

.burger {
  color: #a1acbb;
  stroke: #7f8289;
  transform: scale(2);
}