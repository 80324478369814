.section {
   padding: 20px;
   text-align: center;
}

.health {
    padding: 20px;
    text-align: center;
}

.culture {
    padding: 20px;
    text-align: center;
}

.header {
    text-align: center;
}